$ const { size } = input;

style.scss {
  .ideacon {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
}

<svg class="ideacon" style={ width: size ? `${size}rem` : '', height: size ? `${size}rem` : '' }>
  <use xlink:href=`#${input.name}`></use>
</svg>
